<template>
  <v-container class="px-0">
    <v-card>
      <v-card-text>
        <v-container class="px-0">
          <validation-observer ref="observer">
            <form @submit.prevent="submit">
              <v-row align="center" justify="left">
                <v-col cols="auto" class="pb-0">
                  <p class="primary--text font-weight-bold">パスワード再発行</p>
                </v-col>

                <v-col cols="12" class="pb-0 new-pass">
                  <p class="text--secondary mb-3">
                    新しいパスワード
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password"
                    rules="required:新しいパスワード|verify_password|only_english_lang_allowed"
                  >
                    <v-text-field
                      v-model="password"
                      type="password"
                      placeholder="8文字以上の英数字を設定してください"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      solo
                      background-color="#EAEDF0"
                    >
                    </v-text-field>
                    <span class="post-label"
                      >※英大文字、英小文字、数字をすべて使用してください</span
                    >
                    <div class="v-messages theme--light error--text">
                      {{ errors ? errors[0] : "" }}
                    </div>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <p class="text--secondary mb-3">
                    パスワード再入力
                  </p>
                  <validation-provider
                    v-slot="{ errors }"
                    name="password_confirmation"
                    rules="required:確認用のパスワード|password_confirmed:password"
                  >
                    <v-text-field
                      v-model="confirm_password"
                      type="password"
                      placeholder="もう一度入力してください"
                      :error-messages="errors"
                      :error="errors.length !== 0"
                      solo
                      background-color="#EAEDF0"
                    >
                    </v-text-field>
                  </validation-provider>
                </v-col>

                <v-col cols="12" class="pt-0">
                  <v-btn
                    color="primary"
                    block
                    @click="submit"
                    :disabled="getApiProcessingStatus"
                    :loading="getApiProcessingStatus"
                  >
                    再設定する
                  </v-btn>
                </v-col>
              </v-row>
            </form>
          </validation-observer>
        </v-container>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ResetPasswordPage",
  data() {
    return {
      password: "",
      confirm_password: ""
    };
  },
  computed: {
    token() {
      return this.$route.params.token;
    },
    email() {
      return this.$route.params.email;
    },
    ...mapGetters(["getApiProcessingStatus"])
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          return;
        }
        let email = this.email;
        let password = this.password;
        let password_confirmation = this.confirm_password;
        let token = this.token;

        this.$store
          .dispatch("AUTH_RESET", {
            email,
            password_confirmation,
            password,
            token
          })
          .then(
            response => {
              if (response.status === 200) {
                this.$router.push("/");
              }
            },
            error => {
              console.error(error);
            }
          );
      });
    }
  }
};
</script>

<style scoped>
::v-deep .new-pass .v-text-field__details {
  display: none !important;
  min-height: 0px;
  margin-bottom: 0px;
}
::v-deep .new-pass .v-messages.theme--light.error--text {
  margin-bottom: 10px;
  padding-bottom: 10px;
}
::v-deep .new-pass .post-label {
  font-size: 12px;
  position: relative;
  color: #666666;
}
::v-deep .new-pass .v-messages__message {
  line-height: 1.3 !important;
}
</style>
